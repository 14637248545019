import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

const config = {
  apiKey: "AIzaSyAlEvveWDZneqP6AK4iSMr373XFRtu_m4I",
  authDomain: "web-nickit-se.firebaseapp.com",
  databaseURL: "https://web-nickit-se.firebaseio.com",
  projectId: "web-nickit-se",
  storageBucket: "web-nickit-se.appspot.com",
  messagingSenderId: "1025510220761",
  appId: "1:1025510220761:web:9e3041f8e2d511f7a9afc8"
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAnalyticsModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
