<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <header class="mb-auto">
    </header>
  
    <main class="px-3">
      <h1 class="nickit-logo text-white">NickIT</h1>
      <p class="container-sm text-secondary">Nickit provide proferssional services within solution architecture and software development, helping customers to design, build and release amazing software. If you have a project or something else to discuss do not hesitate to 
          <span class="elink" title="nicklas, text contact, subject Online Contact"></span> us.</p>
    
    </main>
    <footer class="mt-auto text-white-50">
            <div class="col-sm">
                <a class="link-secondary" href="https://www.linkedin.com/in/nicklasohman"><i class="fab fa-linkedin-in"></i></a>
            </div>
    </footer>
  </div>
  

<router-outlet></router-outlet>